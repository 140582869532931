import * as React from "react";
import * as ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { variables, validEmail } from '../Variables';
import { useDispatch } from "react-redux";
import { login } from "../features/userSlice";
import { settoken } from "../features/WhiseTokenSlice";
import { setclienttoken, clearclienttoken } from "../features/ClientTokenSlice";
import { clearcalendaractions, setcalendaractions } from "../features/ClientCalendarActionsSlice";
import { setselectedproperty, clearselectedproperty } from "../features/SelectedPropertySlice";
import { clearSidebarCollapsed } from "../features/SidebarCollapseSlice";

import mindfreeLogo from '../assets/images/login_Logo.svg';
import tickVector from '../assets/images/Vector.svg';
import eyeOff from '../assets/images/eye-off.svg';
import eyeOpen from '../assets/images/eye-open.svg';

const { useState, useEffect } = React;

export const Login = () => {
    const [emailMessage, setEmailMessage] = useState("");
    const [passwordMessage, setPasswordMessage] = useState("");
    const [showhideTickIcon, setShowhideTickIcon] = useState("d-none");
    const [eyeIcon, setEyeIcon] = useState(eyeOff);
    const [passFieldType, setPassFieldType] = useState("password");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [display, setDisplay] = useState("d-none");
    const [clientToken, setClientToken] = useState("");

    const dispatch = useDispatch();

    const showMessage = (type, message) => {
        setMessageType(type);
        setMessage(message);
        setDisplay("d-block");
        const timer = setTimeout(() => {
            setMessageType("");
            setMessage("");
            setDisplay("d-none");

            return () => clearTimeout(timer);
        }, [3000]);
    }

    const handleSubmit = (e) => {
        e.target.setAttribute("disabled", true);
        e.target.style.cursor = "progress";
        document.querySelector("body").style.cursor = "progress";
        let email = document.getElementById("userEmail");
        let pass = document.getElementById("userPassword");
        let isFieldEmpty = false;
        dispatch(clearselectedproperty());
        dispatch(clearclienttoken());
        dispatch(clearSidebarCollapsed());

        let user = {
            UserName: "",
            UserEmail: email.value,
            UserPassword: pass.value,
            Status: true
        }
        let errorMessage = [];

        if (user.UserEmail == "") {
            email.classList.add("empty");
            isFieldEmpty = true;
            setEmailMessage("Enter your email");
        }
        else if (user.UserEmail != "" && validEmail.test(user.UserEmail) == false) {
            email.classList.add("empty");
            isFieldEmpty = true;
            setEmailMessage("Enter a valid email");
        }

        if (user.UserPassword == "") {
            pass.classList.add("empty");
            isFieldEmpty = true;
            setPasswordMessage("Enter your password");
        }

        if (isFieldEmpty == true) {
            e.target.removeAttribute("disabled");
            e.target.style.cursor = "pointer";
            document.querySelector("body").style.cursor = "default";
            return;
        }

        let url = variables.API_URL + `User/AuthenticateUser?`;

        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        axios.post(url, JSON.stringify(user), jsonconfig) // ASP.NET Core API endpoint with header
            .then(response => {
                if (response.data == "Account doesn't exist with this email" || response.data == "Wrong Password") {
                    showMessage("error", response.data);
                    e.target.removeAttribute("disabled");
                    e.target.style.cursor = "";
                    document.querySelector("body").style.cursor = "";
                    return
                } else {
                    dispatch(
                        login(response.data)
                    );
                }
                getToken(response.data);
                e.target.removeAttribute("disabled");
                e.target.style.cursor = "";
                document.querySelector("body").style.cursor = "";
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                e.target.removeAttribute("disabled");
                e.target.style.cursor = "";
                document.querySelector("body").style.cursor = "";
            });
    };

    const getToken = async (userData) => {
        let url = "https://api.whise.eu/token";
        let headers = {
            'Content-Type': 'application/json'
        };
        let body = {
            "Username": variables.WhiseUser,
            "Password": variables.WhisePassword
        };

        try {
            let resp = await axios.post(url, body,
                {
                    headers: headers
                });
            if (resp && resp.data && resp.data.token) {
                dispatch(
                    settoken(resp.data.token)
                );
                getClientToken(resp.data.token, userData);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const getClientToken = (token, userData) => {
        let url = "https://api.whise.eu/v1/admin/clients/token";

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, { "clientId": userData.user.clientId }, config)
            .then(response => {
                setClientToken(response.data.token);
                dispatch(setclienttoken(response.data.token));
                getCalendarActions(response.data.token);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getCalendarActions = (clientToken) => {
        let url = "https://api.whise.eu/v1/calendars/actions/list";

        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, {}, config)
            .then(response => {
                dispatch(
                    setcalendaractions(response.data.calendarActions)
                );
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const resetErrorFieldStyle = (e) => {
        if (e.target.classList.contains("empty")) {
            e.target.classList.remove("empty");
        }

        if (e.target.id == "userEmail") {
            setEmailMessage("");
        }
        else {
            setPasswordMessage("");
        }
    }

    const changeHandlerLoginField = (e) => {
        if (e.target.id == "userEmail" && e.target.value != "") {
            setShowhideTickIcon("");
        }
        else if (e.target.id == "userEmail" && e.target.value == "") {
                setShowhideTickIcon("d-none");
            }
    }

    const handlerShowPassword = (e) => {
        if (passFieldType == "password") {
            setEyeIcon(eyeOpen);
            setPassFieldType("text");
        }
        else {
            setEyeIcon(eyeOff);
            setPassFieldType("password");
        }
    }

    return (
        <>

            <div className={`login-message-box ${messageType == "error" ? "login-error-box" : ""} ${display}`}>
                <div className="login-message-container">
                    <p className="message-box-t mb-0">{message}</p>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                    <div className="login-form-container">
                        <div className="col-12">
                            <div className="img-container mb-4 pb-3">
                                <img src={mindfreeLogo} alt="user vector" />
                            </div>
                            <div className="text-center mb-4 pb-2">
                                <span className="login-title">Login</span>
                            </div>
                            <div className="form-group mb-3 pb-1 position-relative">
                                <label htmlFor="userEmail" className="col-sm-12 form-group-label">Agent ID<span>*</span></label>
                                <div className="col-sm-12 position-relative">
                                    <input type="email" id="userEmail" className="form-control"
                                        placeholder="Agent Id" onFocus={resetErrorFieldStyle} onChange={changeHandlerLoginField} />
                                    <img src={tickVector} className={`input-field-icons ` + showhideTickIcon } alt="tick vector" />
                                </div>
                                <small className="text-danger position-absolute fs-small login-field-error">{emailMessage}</small>
                            </div>

                            <div className="form-group mb-3 pb-1 position-relative">
                                <label htmlFor="password" className="col-sm-12 form-group-label">Password<span>*</span></label>
                                <div className="col-sm-12 position-relative">
                                    <input type={passFieldType} id="userPassword" className="form-control" onChange={changeHandlerLoginField}
                                        placeholder="Password" onFocus={resetErrorFieldStyle} />
                                    <img src={eyeIcon} className="input-field-icons eye-icon" alt="eye vector" onClick={handlerShowPassword} />
                                </div>
                                <small className="text-danger position-absolute fs-small login-field-error">{passwordMessage}</small>
                            </div>
                            <div className="form-check mb-3 pb-1">
                                <input className="form-check-input" type="checkbox" value="" id="checkRemember" />
                                <label className="form-check-label" htmlFor="checkRemember">
                                    Remember Me
                                </label>
                            </div>
                            <div className="text-center">
                                <button type="button" className="site-btn site-btn-padd w-100" onClick={handleSubmit} >Login</button>
                            </div>
                            <div className="text-center mt-3 pt-1">
                                <p className="forgot-pass">Forgot password?<a href="mailto:Info@mindfree.be" className="create-account">Contact Us</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <p className="rights-text">© 2024 Smart Doc . Alright reserved.<a className="login-bottom-links ms-2">Terms & Conditions</a><a className="login-bottom-links ms-2">Privacy Policy</a></p>
                    </div>
                </div>
            </div>
        </>
    )
}