import { createSlice } from "@reduxjs/toolkit";

export const CreateEventDetailSlice = createSlice({
    name: "eventDetail",
    initialState: {
        eventDetail: {
            event: null,
            availableHours: null,
            invitee: null
        },
    },
    reducers: {
        seteventdetail: (state, action) => {
            state.eventDetail = action.payload;
        },
        cleareventdetail: (state, action) => {
            state.eventDetail = null;
        }
    }
})

export const { seteventdetail, cleareventdetail } = CreateEventDetailSlice.actions;

export default CreateEventDetailSlice.reducer;