import * as React from "react";
import { useSelector } from "react-redux";
import axios from 'axios';

import { Modal, Button } from 'react-bootstrap';
import { NewEvent } from "../eventforms/NewEvent";
import { NewEventDetails } from "../eventforms/NewEventDetails";
import { SetupInvitee } from "../eventforms/SetupInvitee";
import { ConfirmSlot } from "../eventforms/ConfirmSlot";

import plusBlueIcon from '../assets/images/plus_blue.svg';
import closeIcon from '../assets/images/cross-close.svg';
import calendarIcon from '../assets/images/calendar.svg';
import propertyIcon from '../assets/images/buildings-alt.svg';
import userIcon from '../assets/images/user-alt.svg';
import clockIcon from '../assets/images/clock.svg';
import locationPinIcon from '../assets/images/location-pin.svg';
import userCircle from '../assets/images/user-circle.svg';

const { useState, useEffect } = React;

export const EventModal = ({ showModal, handleClose, showMessage, whiseAgents, saveNewWhiseEvent, preview, callBackToUpdateCalendarFilter, previewEventDetail, tobeConfirmedIds, confirmedIds, freeSlotIds, deleteWhiseCalendarEvent, acceptToBeConfirmedEvents }) => {
    const [previewEventEstate, setPreviewEventEstate] = useState(null);
    const [previewEventUser, setPreviewEventUser] = useState(null);
    const [previewEventContact, setPreviewEventContact] = useState(null);
    const [eventTime, setEventTime] = useState("");

    const reduxAgent = useSelector(state => state.agentFilter.agentFilter);
    const calendarActions = useSelector(state => state.calendarActions.calendarActions);
    const reduxClientToken = useSelector(state => state.whiseClientToken.whiseClientToken);

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

    const deleteEvent = (e) => {
        let eventId = e.target.getAttribute("eventid");
        deleteWhiseCalendarEvent(eventId);
    }

    const acceptEventBooking = (e) => {
        let event = e.target.getAttribute("event");
        acceptToBeConfirmedEvents(JSON.parse(event));
    }

    const getEstateById = (estateId) => {
        let url = "https://api.whise.eu/v1/estates/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${reduxClientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post('/user', document.querySelector('#my-form'), {
            headers: {
                'Content-Type': 'application/json'
            }
        })

        axios.post(url, {
            "Filter": {
                "EstateIds": [estateId],
            }
        }, config)
            .then(response => {
                if (response.data.estates != undefined) {
                    setPreviewEventEstate(response.data.estates[0]);
                }
            })
            .catch(error => {
                console.log("error", error)
            });
    }

    useEffect(() => {
        if (previewEventDetail != null) {
            if (previewEventDetail.estates != undefined && previewEventDetail.estates.length > 0) {
                getEstateById(previewEventDetail.estates[0].estateId)
                //setPreviewEventEstate(previewEventDetail.estates[0]);
            }
            if (previewEventDetail.users != undefined && previewEventDetail.users.length > 0) {
                setPreviewEventUser(previewEventDetail.users[0]);
            }
            if (previewEventDetail.contacts != undefined && previewEventDetail.contacts.length > 0) {
                setPreviewEventContact(previewEventDetail.contacts[0]);
            }


            let startDate = new Date(previewEventDetail.startDateTime);
            var eventdate = startDate.getDate();

            let starthours = startDate.getHours() < 10 ? "0" + startDate.getHours() : startDate.getHours();
            let startminutes = startDate.getMinutes() < 10 ? "0" + startDate.getMinutes() : startDate.getMinutes();
            var start = starthours + ":" + startminutes;

            let endDate = new Date(previewEventDetail.endDateTime);
            let endhours = endDate.getHours() < 10 ? "0" + endDate.getHours() : endDate.getHours();
            let endminutes = endDate.getMinutes() < 10 ? "0" + endDate.getMinutes() : endDate.getMinutes();
            var end = endhours + ":" + endminutes;
            let day = days[startDate.getDay()];

            let finalTime = day + ", " + eventdate + " " + monthNames[startDate.getMonth()] + ": " + start + " - " + end;
            setEventTime(finalTime)
        }
    }, [previewEventDetail])

    return (
        <>
            {
                preview == false ?
                    <Modal show={showModal} onHide={handleClose} className="modalStyle">
                        <Modal.Body className="p-0">
                            <div className="modal-container">
                                {
                                    reduxAgent == null || reduxAgent.length == 0 ?
                                        <NewEvent handleClose={handleClose} showMessage={showMessage} callBackToUpdateCalendarFilter={callBackToUpdateCalendarFilter} />
                                        :
                                        <NewEventDetails handleClose={handleClose} whiseAgents={whiseAgents} saveNewWhiseEvent={saveNewWhiseEvent} />
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                    :
                    <Modal show={showModal} onHide={handleClose} className="modalStyle">
                        <Modal.Body className="p-0">
                            <div className="modal-container">
                                {
                                    previewEventDetail != null ? 

                                        <>
                                            <div className={`d-flex justify-content-between ${previewEventDetail.status == "Free Slot" || previewEventDetail.status == "" ? "mb-4" : "mb-2"}`}>
                                                <p className="form-title"> Event Details</p>
                                                <img src={closeIcon} onClick={handleClose} className="cursor-pointer" />
                                            </div>
                                            {
                                                previewEventDetail.status != "Free Slot" && previewEventDetail.status != "" ?
                                                    <div className='status text-white font-size14 d-inline' style={{ background: previewEventDetail.background }} >
                                                        {previewEventDetail.status}
                                                    </div>
                                                    :
                                                    ""
                                            }
                                            <div className={`d-flex justify-content-between align-items-center border-bottom-end mb-3 ${previewEventDetail.status == "Free Slot" || previewEventDetail.status == "" ? "" : "mt-4"}`}>
                                                <p className="subtitle mb-0"><img src={propertyIcon} className="me-2" /> Property</p>
                                                <div className="text-end">
                                                    <p className="subtitle mb-0">{previewEventEstate != null ? "ID " + previewEventEstate.id : "None"}</p>
                                                    {
                                                        previewEventEstate != null ?
                                                            <p className="small-grey-text mb-0">
                                                                <img src={locationPinIcon} className="me-2" />
                                                                {
                                                                    (previewEventEstate.address == undefined ? "" : previewEventEstate.address) + " " +
                                                                    (previewEventEstate.number == undefined ? "" : previewEventEstate.number) + ", " +
                                                                    (previewEventEstate.zip == undefined ? "" : previewEventEstate.zip) + " " +
                                                                    (previewEventEstate.city == undefined ? "" : previewEventEstate.city)
                                                                }
                                                            </p>
                                                            :

                                                            <p className="small-grey-text mb-0">
                                                            </p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center border-bottom-end mb-3 pt-3 border-top-light">
                                                <p className="subtitle align-items-center mb-0"><img src={userIcon} className="me-2" /> Agent</p>
                                                <div className="text-end">
                                                    <p className="subtitle mb-0">{previewEventUser != null ? previewEventUser.firstName + " " + previewEventUser.name : ""}</p>
                                                    <p className="small-grey-text mb-0">
                                                        ID: {previewEventUser != null ? previewEventUser.userId : ""}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-top border-bottom-end mb-3 pt-3 border-top-light">
                                                <p className="subtitle mb-0"><img src={clockIcon} className="slots-img me-2" /> Time</p>
                                                <div className="subtitle text-end">
                                                    <span>{eventTime != "" ? eventTime : ""}</span>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center border-bottom-end mb-4 pt-3 border-top-light">
                                                <p className="subtitle d-flex align-items-center mb-0"><img src={userCircle} className="me-2" /> Contact</p>
                                                <div className="text-end">
                                                    <p className="subtitle mb-0">{previewEventContact != null ? previewEventContact.firstName : ""}</p>
                                                    <p className="small-grey-text mb-0">
                                                        {previewEventContact != null ? "ID: " + previewEventContact.contactId : "None"}
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                                previewEventDetail.status == "Free Slot" || previewEventDetail.status == "" ?
                                                    <div className="d-flex">
                                                        <button type="button" className="filter-btn site-btn-padd border-red text-red w-100 me-3" eventid={previewEventDetail.eventId} onClick={deleteEvent}>Delete</button>
                                                        <button type="button" className="site-btn site-btn-padd w-100">Edit</button>
                                                    </div>
                                                    :
                                                    previewEventDetail.status == "To Be Confirmed" ?
                                                        <div className="d-flex">
                                                            <button type="button" className="filter-btn site-btn-padd border-red text-red w-100 me-3" eventid={previewEventDetail.eventId} onClick={deleteEvent}>Delete</button>
                                                            <button type="button" className="site-btn site-btn-padd w-100" event={JSON.stringify(previewEventDetail)} onClick={acceptEventBooking}>Confirm The Booking</button>
                                                        </div>
                                                        :
                                                        <div className="d-flex">
                                                            <button type="button" className="filter-btn site-btn-padd border-red text-red w-100" eventid={previewEventDetail.eventId} onClick={deleteEvent}>Delete</button>
                                                        </div>
                                            }
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
            }
        </>
    )
}





