import { createSlice } from "@reduxjs/toolkit";

export const ClientCalendarActionsSlice = createSlice({
    name: "calendarActions",
    initialState: {
        calendarActions: null,
    },
    reducers: {
        setcalendaractions: (state, action) => {
            state.calendarActions = action.payload;
        },
        clearcalendaractions: (state, action) => {
            state.calendarActions = null;
        }
    }
})

export const { setcalendaractions, clearcalendaractions } = ClientCalendarActionsSlice.actions;

export default ClientCalendarActionsSlice.reducer;