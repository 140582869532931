import * as React from "react";
import axios from 'axios';
import { useSelector } from "react-redux";

import plusBlueIcon from '../assets/images/plus_blue.svg';
import closeIcon from '../assets/images/cross-close.svg';
import calendarIcon from '../assets/images/calendar.svg';
import propertyIcon from '../assets/images/buildings-alt.svg';
import userIcon from '../assets/images/user-alt.svg';
import clockIcon from '../assets/images/clock.svg';
import locationPinIcon from '../assets/images/location-pin.svg';
import warning from '../assets/images/warning.svg';

const { useState, useEffect } = React;

export const ConfirmSlot = ({ handleClose, saveNewWhiseEvent, calendarActions, whiseAgents }) => {
    const [availableSlots, setAvailableSlots] = useState([]);

    const reduxStatus = useSelector(state => state.statusFilter.statusFilter);
    const reduxAgent = useSelector(state => state.agentFilter.agentFilter);
    const reduxEventDetail = useSelector(state => state.eventDetail.eventDetail);
    const reduxProperty = useSelector(state => state.selectedProperty.selectedProperty);

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    useEffect(() => {
        if (reduxEventDetail.availableHours.length > 0) {

            const datesArray = [...reduxEventDetail.availableHours];

            let sortedList = datesArray.sort(function (a, b) {
                return new Date(a.StartDateTime) - new Date(b.StartDateTime);
            });

            setAvailableSlots(sortedList)
        }

    }, [reduxEventDetail])
    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <ul className="form-steps">
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar active"></li>
                </ul>
                <img src={closeIcon} onClick={handleClose} className="cursor-pointer" />
            </div>
            <div>
                <p className="form-title mb-2">New Event</p>
            </div>
            <div className="warning mb-2">
                <img src={warning} className="me-3" />
                <p className="mb-0">
                    Only future events will be catered.
                </p>
            </div>
            <div>
                <p className="subtitle mb-4">Please confirm your new slot</p>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom-end mb-4">
                <p className="subtitle mb-0"><img src={calendarIcon} className="me-2" />Calendar Type</p>
                <p className="subtitle mb-0">
                    {
                        calendarActions.length > 0 ?
                            calendarActions.map(item => {
                                if (item.id == reduxEventDetail.event.EventType) {
                                    return (
                                        <span className="visit-type-pill">{item.name}</span>
                                    )
                                }
                            })
                            :
                            <></>
                    }
                </p>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom-end mb-4">
                <p className="subtitle mb-0"><img src={propertyIcon} className="me-2" /> Property</p>
                <div className="text-end">
                    <p className="subtitle mb-0">ID {reduxProperty.id}</p>
                    <p className="small-grey-text mb-0">
                        <img src={locationPinIcon} className="me-2" />
                        {
                            (reduxProperty.address == undefined ? "" : reduxProperty.address) + " " +
                            (reduxProperty.number == undefined ? "" : reduxProperty.number) + ", " +
                            (reduxProperty.zip == undefined ? "" : reduxProperty.zip) + " " +
                            (reduxProperty.city == undefined ? "" : reduxProperty.city)
                        }
                    </p>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom-end mb-4">
                <p className="subtitle mb-0"><img src={userIcon} className="me-2" /> Agent</p>
                <div className="text-end">
                    {
                        reduxAgent.length > 0 ?
                            reduxAgent.map(item => {
                                return (
                                    <>
                                        <p className="subtitle mb-0">{item.agentDetail.firstName + " " + item.agentDetail.name}</p>
                                        <p className="small-grey-text mb-0">
                                            {item.value}
                                        </p>
                                    </>
                                )
                            })
                            :
                            <></>
                    }
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-top border-bottom-end mb-4">
                <p className="subtitle mb-0"><img src={clockIcon} className="slots-img me-2" /> Slot</p>
                <div className="slots-container subtitle text-end">
                    {
                        availableSlots.length > 0 ?
                            availableSlots.map(item => {
                                let startDate = new Date(item.StartDateTime);
                                startDate.setMinutes(startDate.getMinutes() + startDate.getTimezoneOffset());
                                var eventdate = startDate.getDate();

                                let starthours = startDate.getHours() < 10 ? "0" + startDate.getHours() : startDate.getHours();
                                let startminutes = startDate.getMinutes() < 10 ? "0" + startDate.getMinutes() : startDate.getMinutes();
                                var start = starthours + ":" + startminutes;

                                let endDate = new Date(item.EndDateTime);
                                endDate.setMinutes(endDate.getMinutes() + endDate.getTimezoneOffset());
                                let endhours = endDate.getHours() < 10 ? "0" + endDate.getHours() : endDate.getHours();
                                let endminutes = endDate.getMinutes() < 10 ? "0" + endDate.getMinutes() : endDate.getMinutes();
                                var end = endhours + ":" + endminutes;

                                return (
                                    <><span>{item.Day + ", " + eventdate + " " + monthNames[startDate.getMonth()] +  ": " + start + " - " + end}</span><br/></>
                                )
                            })
                            :
                            ""
                    }
                </div>
            </div>
            <div className="d-flex">
                <button type="button" className="site-btn site-btn-padd w-100" onClick={saveNewWhiseEvent}>Create slot and synchonise with WHISE</button>
            </div>
        </>
    )
}




