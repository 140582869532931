import React, { Component, useState, cloneElement, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import axios from 'axios';
import './custom.css';
import { Login } from "./components/Login";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { logout } from "./features/userSlice";
import { Sidebar } from "./components/Sidebar";
import { Dropdown } from 'react-bootstrap';
import successTick from './assets/images/success_tick.svg';
import exclamation from './assets/images/error_cross.svg';
import cross from './assets/images/cross-close.svg';
import { EventHandler } from './components/EventHandler';

const App = () => {
    const user = useSelector(state => state.user.user);
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [display, setDisplay] = useState("d-none");
    const [clientSettings, setClientSettings] = useState(null);
    const dispatch = useDispatch();

    const whiseUserToken = useSelector(state => state.whiseUser.whiseToken);

    const url = window.location.href;

    const date = new Date();
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    const currentDateTime = date.toISOString();

    const expiryDate = user != null && user.expiryDateTime != undefined ? new Date(user.expiryDateTime) : new Date();
    expiryDate.setMinutes(expiryDate.getMinutes() - expiryDate.getTimezoneOffset());
    const expiryDateTime = expiryDate.toISOString();

    const showMessage = (type, message) => {
        setMessageType(type);
        setMessage(message);
        setDisplay("d-block");
        const timer = setTimeout(() => {
            setMessageType("");
            setMessage("");
            setDisplay("d-none");

            return () => clearTimeout(timer);
        }, [3000]);
    }

    const getClientSettings = () => {
        let url = 'https://api.whise.eu/v1/admin/clients/settings';

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${whiseUserToken}`
        };

        if (user.user != undefined) {
            let body = {
                ClientId: user.user.clientId
            };


            axios.post(url, body,
                {
                    headers: headers
                })
                .then(response => {
                    setClientSettings(response.data.settings);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }

    useEffect(() => {
        if (whiseUserToken != null) {
            getClientSettings()
        }
    }, [whiseUserToken])

    return (
        <>
            <div id="messageBox" className={`message-box ${messageType == "error" && "error-box" || "success-box"} ${display}`}>
                <div className="message-container">
                    <img src={cross} className="close-message-box" />
                    {
                        messageType == "error" ?
                            <img src={exclamation} className="mb-3" />
                            :
                            <img src={successTick} className="mb-3" />
                    }
                    <p className="message-box-t mb-3">{message}</p>
                    <button className="site-btn">
                        {
                            messageType == "error" ?
                                "Oops!"
                                :
                                "Great!"
                        }
                    </button>
                </div>
            </div>
            {
                user && currentDateTime < expiryDateTime ? <Layout>
                    <Sidebar />
                    <div className="container-fluid main pb-4">
                        <div className="d-flex justify-content-between pt-4 pb-5 px-4">
                            <div>
                                <h5 className="page-heading mb-0 me-4">
                                    {clientSettings != null ? clientSettings.name : ""}
                                </h5>
                            </div>
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle className="rounded-pill" id="dropdown-basic">
                                        {user.user.userName}
                                        <span className="customer-id">ID: {user.user.userId}</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <Routes>
                            {AppRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                let clonedElement = cloneElement(element, { showMessage: showMessage });
                                if (index == 0) {
                                    clonedElement = cloneElement(element, { showMessage: showMessage })
                                }
                                if (index == 3) {
                                    clonedElement = cloneElement(element, { showMessage: showMessage, clientSettings: clientSettings })
                                }
                                return <Route key={index} {...rest} element={clonedElement} />;
                            })}
                        </Routes>
                    </div>
                </Layout>
                    :
                    <Layout>
                        <div className="container-fluid">
                            {
                                url.indexOf("eventhandler") > 0 ?
                                    <Routes>
                                        <Route path="/eventhandler/:contactId?/:eventId?/:clientId?/:officeId?/:contactName?" element={<EventHandler />} />
                                    </Routes>
                                    :
                                    <Login />
                            }
                        </div>
                    </Layout>
            }
        </>

    );
}

export default App;